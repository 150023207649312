/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: {
    POST: 'POST',
    PUT: 'PUT',
    GET: 'GET',
    DELETE: 'DELETE',
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: 'application/json',
    MULTIPART_FORM_DATA: 'multipart/form-data',
    APPLICATION_FORM_URLENCODED: 'application/x-www-form-urlencoded',
    IMAGE_PNG: 'image/png',
  },
  DEVICE_TYPE: {
    WEB: 'web',
  },
  API_END_POINT: {},
};
export const eventConstants = {
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_LOADER: 'SHOW_LOADER',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  GET_IMAGE: 'GET_IMAGE',
  TOAST: 'TOAST',
};
export const genericConstants = {
  HOME: 'home',
  BLOCK: 'block',
  COLLECTION: 'collection',
  LIVE_GAME: 'live-game',
  QUIZZES: 'quizzes',
  FAN_BLOCK: 'fan-block',
  MATCHES: 'matches',
  Leagues: 'leagues',
  FORGOT_PASSWORD: 'forgot-password',
  VALIDATE_OTP: 'validate-otp',
  SIGN_IN: 'sign-in',
  ADD_USER: 'add-user',
  PROFILE: 'profile',
  PACKS: 'packs',
  CUSTOMER_SUPPORT: 'support',
  ONBOARDING: 'onboarding',
  TEAM_STATS: 'team-stats',
  SQUAD_VIEW: 'squad-details',
  TEAM_INFO: 'team-info',
  CHOOSE_TEAM: 'choose-team',
  HEAD_TO_HEAD: 'head-to-head',
  FANBLOCKS: 'fanblocks',
  FANBLOCK: 'fanblock',
  REVEAL: 'reveal',
  PACK_DETAILS: 'pack',
  PRIZE_DETAILS: 'prizes',
  PREDICTION_DETAILS: 'prediction-details',
};
export const successMessage = {
  LOG_IN_SUCCESSFULLY: 'Login successfully',
  WALLET_SUCCESSFULLY_CREATED: 'Wallet created successfully',
  LOG_OUT_SUCCESSFULLY: 'Logout successfully',
  MATCH_STARTED: 'Match started',
  EMIAL_SENT: 'Forgot password email sent successfully',
  OTP_VERIFIED: 'OTP verified',
  USER_ADDED: 'User added successfully',
  PACK_REVEALED: 'Packs revealed successfully',
  PROFILE_UPDATED_SUCCESFULLY: 'Profile updated successfully',
  USER_DETAILS_UPDATED: 'Current team updated',
  BLOCK_DETAILS_FETCHED: 'Block details fetched successfully',
  SQUAD_DETAILS_FETCHED: 'Squad details fetched successfully',
  TEXT_COPIED_SUCCESSFULLY: 'Text copied successfully',
  OTP_SENT_TO_MAIL: "We've sent an OTP to your email",
  PROFILE_UPDATED: 'User profile updated',
  QUESTIONS_SUBMITTED_SUCCESSFULLY: 'Questions submitted successfully',
};
export const errorMessage = {
  USER_ALREADY_EXIST: 'User already exist',
  USER_LOGIN_FAILED: 'Please enter valid user details',
  UNDER_PROGRESS: 'Under progress',
  MATCH_NOT_STARTED: 'This match is not started yet',
  ONLY_ADMIN_CAN_DO: 'Only Admin can do backfilling of data',
  ENTER_EMAIL_ADDRESS: ' Please enter email address',
  ENTER_OTP: 'Please enter one time password',
  FAILD_TO_EMAIL: 'Failed to send email',
  USER_NOT_EXIST: 'User not exist',
  FIELD_REQUIRED: 'All fields required',
  FAILED_TO_REVEALED_PACKS: 'Failed to revealed packs',
  PLEASE_FILL_USERNAME: 'Please fill username',
  MARKETING_OPTION_IS_MUST: 'Please check the box to proceed',
  FAILED_TO_GET_TEAM: 'Failed to get teams',
  FAILED_TO_GET_BLOCK_DETAILS: 'Failed to get block details',
  FAILED_TO_GET_SQUAD_DETAILS: 'Failed to get squad details',
  PLEASE_CHOOSE_YOUR_TEAM: 'Please choose your team',
  WORK_INPROGRESS: 'Work Inprogress',
  TEXT_COPY_FAILED: 'Text could not be copied',
  WALLET_CREATION_ERROR: ' Error while creating wallet',
  ENTER_VALID_EMAIL: 'Please enter a valid email',
  EMAIL_REQUIRED: 'Email is required',
  PLEASE_PROVIDE_VALID_OTP: 'Please provide a valid OTP',
  PLEASE_SELECT_CHECKBOX:
    'Please confirm that you are at least 18 years old to proceed',
  PLEASE_AGREE_TERMS: 'Please agree to the terms and conditions to proceed',
  FAILED_TO_SEND_AUTH_CODE: 'Failed to send the authentication code',
  NETWORK_ERROR_TRY_AGAIN: 'Network error. Please try again',
  CANNOT_SIGNUP_WITH_THIS_EMAIL: "You can't signup using this email",
  USER_NOT_EXIST_PLEASE_SIGN_UP: 'User does not exist, Please sign up',
  PACK_REVEAL_FAILED: 'Failed to reveal Pack',
  QUESTIONS_FAILED_TO_SUBMIT: 'Questions failed to submit, please answer all the questions and try again',
};
export const cookiesConstants = {
  IS_LOGGED_IN: 'isLoggedIn',
  PROFILE_DETAILS: 'profileDetails',
  VIEWED_LIVE_MATCH_FIRST_HALF: 'viewedFirstHalf',
  VIEWED_LIVE_MATCH_SECOND_HALF: 'viewedSecondHalf',
  IP_ADDRESS: 'ipAddress',
  FAN_EMAIL: 'fanEmail',
  SHOW_BETA_NOTICE_MODAL: 'showBetaNoticeModal',
  MARKETING_OTP_IN: 'marketingOptIn',
  RETURNING_VISITOR: 'returningVisitor',
  ADDED_BLOCK_NAME: 'addedBlockName',
  JWT_TOKEN: 'jwtToken',
  COMPLETED_DAILY_QUIZ: 'completedDailyQuiz',
};
export const responseConstant = {
  SUCCESS: 'success',
  ERROR: 'error',
};
export const zoneConstants = {
  ULTRARARE: 'Ultra Rare',
  RARE: 'Rare',
  BASE: 'Base',
  PREMIUM: 'Premium',
  CORNER: 'Corner',
  PENALTY: 'Penalty',
  CENTER: 'Center',
};
export const eventTypeConstants = {
  PASS: 'pass',
  ASSIST: 'assist',
  GOAL: 'goal',
  OWN_GOAL: 'own_goal',
  SUCCESSFUL_TACKLE: 'successful_tackle',
  BLOCK: 'block',
  YELLOW_CARD: 'yellow_card',
  RED_CARD: 'red_card',
  FOUL: 'foul',
  FREE_KICK: 'free_kick',
  HEADER: 'header',
  CORNER: 'corner',
};
export const contestantId = {
  AC_MILAN_ID: '9dntj5dioj5ex52yrgwzxrq9l',
  TOTTENHAM_ID: '22doj4sgsocqpxw45h607udje',
};
export const socialMediaLink = {
  FACEBOOK: 'https://www.facebook.com/FanblockFootball',
  TWITTER: 'https://twitter.com/Fanblock_ftball',
  INSTAGRAM: 'https://www.instagram.com/Fanblock_football/',
  TIK_TOK: 'https://www.tiktok.com/@fanblock_football',
  YOU_TUBE: 'https://www.youtube.com/@fanblock_football',
  LINKED_IN: 'https://www.linkedin.com/company/fanblock/',
};

export const shopLinks = {
  SHOP: 'https://fanblock.com',
};

export const supportLinks = {
  HELP: 'https://help.fanblock.com/',
  TERMS: 'https://fanblock.com/terms',
  privacy: 'https://fanblock.com/privacy',
  legal:
    'https://docs.google.com/document/d/1HUpK4XzVOD_08TVpkDV2pNgmisVma5o2avgy86TBubs/edit',
};

export const userActionConstant = {
  LOGGED_IN: 'LOGGED_IN',
  VIEWED_LIVE_MATCH_FIRST_HALF: 'VIEWED_LIVE_MATCH_FIRST_HALF',
  VIEWED_LIVE_MATCH_SECOND_HALF: 'VIEWED_LIVE_MATCH_SECOND_HALF',
  ADDED_BLOCK_NAME: 'ADDED_BLOCK_NAME',
  PACK_REVEALED: 'PACK_REVEALED',
};

export const REVEAL_DATE = '2023-10-21T12:00:00Z';

export const navConstants = {
  TOGGLE_MOBILE_MENU: 'TOGGLE_MOBILE_MENU',
  TOGGLE_ACCOUNT_MENU: 'TOGGLE_ACCOUNT_MENU',
  TOGGLE_CLUB_SWITCHER: 'TOGGLE_CLUB_SWITCHER',
  SET_MOBILE_MENU: 'SET_MOBILE_MENU',
};

export type TeamMappings = {
  Juventus: string;
  Tottenhamv: string;
  'Atlético Madrid': string;
  ACMilan_Men: string;
  Spurs: string;
};

export const teamMappings: TeamMappings = {
  Juventus: process.env.REACT_APP_JUVENTUS_SALESFORCE_TEAM_ID || 'N/A',
  Tottenhamv: process.env.REACT_APP_TOTTENHAMV_SALESFORCE_TEAM_ID || 'N/A',
  'Atlético Madrid':
    process.env.REACT_APP_ATLETICO_MADRID_SALESFORCE_TEAM_ID || 'N/A',
  ACMilan_Men: process.env.REACT_APP_ACMILAN_SALESFORCE_TEAM_ID || 'N/A',
  Spurs: process.env.REACT_APP_SPURS_SALESFORCE_TEAM_ID || 'N/A',
};

export const USER_NAME_PATTERN = /^[a-zA-Z0-9]+$/;

export const SCREEN_WIDTH = {
  mobile: 768,
  tablet: 990,
  desktop: 1280,
};